import React from "react"
import SEO from "../components/seo"
import TopHeader from "../components/header/TopHeader"
import Footer from "../components/footer"

import logo from "../images/logo_afdt.png";
import clock from "../images/clock2.png";

import "../styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const Temp = () => {
    return (
        <div className="page">
            <SEO titleSEO="Page temporaire" />
            <TopHeader disabled={true} />
            <div className="navbar-temp">
                <img src={logo} alt="logo afdt" className="img-fluid" />
            </div>
            <div className="temp-content">
                <div className="container">
                    <img src={clock} alt="logo horloge" className="img-fluid mx-auto d-block" />
                    <div className="temp-content-text mx-auto">
                        <h1>Un peu de patience,<br />
                        <span className="asap-semi-bold">le site sera en ligne<br />
                        d'ici quelques jours</span></h1>
                        <p>En attendant, contactez-nous à <span className="asap-bold">agence.montpellier@afdtoccitanie.fr</span></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Temp;